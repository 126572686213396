"use client"
import React from "react"

import Timeline from "../elements/Timeline"

interface Props {
}

const Experience: React.FC<Props> = (props) => {
    return (
        <React.Suspense fallback={
             <div className="loader-container">
                  <div className="loader">
                  </div>
                </div>}>
            <section className="section" id="experience">
                <div className="container">
                    <Timeline/>
                </div>
            </section>
        </React.Suspense>
    );
}

export default Experience
